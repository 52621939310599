import React from 'react'
import { StyledInput } from './style';

function Input({ ...props }) {
  return (
    <StyledInput {...props} />
  );
}

export default Input;

