import React, { SyntheticEvent } from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EntryCard from '../components/EntryCard/EntryCard';
import InputGroup from '../components/InputGroup/InputGroup';
import Input from '../components/Input';
import Button from '../components/Button';
import { Navigate, useParams } from 'react-router-dom';

function Register() {
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [passok, setpassok] = useState(false);
  const [result, setResult] = useState('');
  const [userCreated, setUserCreated] = useState(false);
  const [tokenResult, setTokenResult] = useState();

  let { token } = useParams();

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API}/api/v1/users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: {
          firstname,
          surname,
          password,
          token
        }
      })
    });

    const content = await response.json();
    console.log(content);
    setResult(content);
    // check the user was created
    if (content.user) {
      setUserCreated(true);
    }
  }

  function checkPasswordMatch(pass2) {
    if (pass2 === password && pass2.len >= 8) {
      setpassok(true);
    } else {
      setpassok(false);
    }
  }

  useEffect(() => {
    // Check if token is valid and return the e-mail address
    async function checkToken() {
      let response = await fetch(`${process.env.REACT_APP_API}/api/v1/user/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token
        })
      });
      const content = await response.json();
      setTokenResult(content);
    }
    checkToken();
    // check the user was created
  }, [token]);

  if ((tokenResult && tokenResult.email === false) || token === "" || userCreated) {
    return <Navigate redirect to="/login" />
  }


  return (
    <EntryCard>
      <h2>Register</h2>
      <form onSubmit={submit}>
        <InputGroup>
          <label htmlFor="login-email"><strong>E-Mail Address</strong></label><br />
          <label htmlFor="login-email">{tokenResult ? tokenResult.email : ""}</label>
        </InputGroup>
        <InputGroup>
          <label htmlFor="firstname">Firstname</label>
          <Input type="text" onChange={e => setFirstname(e.target.value)} placeholder="Firstname" id="firstname" />
          {result.firstname ? <label htmlFor="firstname" className="label error">{result.firstname}</label> : ''}
        </InputGroup>
        <InputGroup>
          <label htmlFor="surname">Surname</label>
          <Input type="text" onChange={e => setSurname(e.target.value)} placeholder="Surname" id="surname" />
          {result.surname ? <label htmlFor="surname" className="label error">{result.surname}</label> : ''}
        </InputGroup>

        <InputGroup>
          <label htmlFor="password">Password</label>
          <Input type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" id="password" />
          {result.password ? <label htmlFor="password" className="label error">{result.password}</label> : ''}
        </InputGroup>
        <InputGroup>
          <label htmlFor="password2">Verify Password</label>
          <Input type="password" onChange={e => checkPasswordMatch(e.target.value)} placeholder="Password" id="password" />
          {!passok && password.len > 0 ? <label htmlFor="password" className="label error">Passwords must match and of a reasonable security</label> : ''}
        </InputGroup>

        <Button type="submit" full>Create Account</Button>
      </form>
      <span>
        Already have an account?
        <Link to="/Login">Login</Link>
      </span>
    </EntryCard>
  )
}

export default Register;
