import React, { SyntheticEvent } from 'react'
import { useState, useEffect } from 'react';
import EntryCard from '../components/EntryCard/EntryCard';
import InputGroup from '../components/InputGroup/InputGroup';
import Input from '../components/Input';
import Button from '../components/Button';
import { Navigate, useParams } from 'react-router-dom';

function NewPassword() {
  const [password, setPassword] = useState('');
  const [passok, setpassok] = useState(false);
  const [result, setResult] = useState('');
  const [passReset, setPassReset] = useState(false);
  const [tokenResult, setTokenResult] = useState();

  let { token } = useParams();

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API}/api/v1/user/new_pass`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: {
          password,
          token
        }
      })
    });

    const content = await response.json();
    setResult(content);
    // check the user was created
    if (content.status === 'password updated') {
      setPassReset(true);
    }
  }

  function checkPasswordMatch(pass2) {
    if (pass2 === password && pass2.len >= 8) {
      setpassok(true);
    } else {
      setpassok(false);
    }
  }

  useEffect(() => {
    // Check if token is valid and return the e-mail address
    async function checkToken() {
      let response = await fetch(`${process.env.REACT_APP_API}/api/v1/user/pass_token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token
        })
      });
      const content = await response.json();
      setTokenResult(content);
    }
    checkToken();
    // check the user was created
  }, [token]);

  if ((tokenResult && tokenResult.email === false) || token === "" || passReset) {
    return <Navigate redirect to="/login" />
  }


  return (
    <EntryCard>
      <h2>Password Reset</h2>
      <form onSubmit={submit}>
        <InputGroup>
          <label htmlFor="login-email"><strong>E-Mail Address</strong></label><br />
          <label htmlFor="login-email">{tokenResult ? tokenResult.email : ""}</label>
        </InputGroup>

        <InputGroup>
          <label htmlFor="password">Password</label>
          <Input type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" id="password" />
          {result.password ? <label htmlFor="password" className="label error">{result.password}</label> : ''}
        </InputGroup>
        <InputGroup>
          <label htmlFor="password2">Verify Password</label>
          <Input type="password" onChange={e => checkPasswordMatch(e.target.value)} placeholder="Password" id="password" />
          {!passok && password.len > 0 ? <label htmlFor="password" className="label error">Passwords must match and of a reasonable security</label> : ''}
        </InputGroup>

        <Button type="submit" full>Reset Password</Button>
      </form>
    </EntryCard>
  )
}

export default NewPassword;
