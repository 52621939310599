import React, { SyntheticEvent } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import EntryCard from '../components/EntryCard/EntryCard';
import InputGroup from '../components/InputGroup/InputGroup';
import Input from '../components/Input';
import Button from '../components/Button';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [tokCreated, setTokCreated] = useState(false);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API}/api/v1/user/reset_password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: {
          email
        }
      })
    });

    const content = await response.json();
    if (content.status === "ok") {
      setTokCreated(true);
      console.log("Token created")
    }
  }

  function WaitForEmail() {
    return (
      <EntryCard>
        <h2>E-Mail Sent</h2>
        <p>If your e-mail address is in our database then a reset link has been sent. Please check your mail and follow the link to reset your password</p>
      </EntryCard>
    )
  }

  return (
    <>
    { tokCreated ? <WaitForEmail /> :
      <EntryCard>
        <h2>Reset Password</h2>
        <form onSubmit={submit}>
          <InputGroup>
            <label htmlFor="login-email">E-Mail Address</label>
            <Input type="text" onChange={e => setEmail(e.target.value)} placeholder="E-mail address" id="email" />
          </InputGroup>

          <Button type="submit" full>Reset Password</Button>
        </form>
        <span>Trying to Login? <Link to="/login">Login</Link> </span>
        <span>Don't yet have an account? <Link to="/signup">Sign up</Link> </span>

      </EntryCard>
    }
    </>
  )
}

export default PasswordReset;
