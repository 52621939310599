import React from 'react'
import { Navigate } from "react-router-dom";

function Logout() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('firstname');
  return (<Navigate to="/" />);
}


export default Logout;
