import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import youtubelogo from '../components/images/youtube-logo.png';

function Footer() {
  return (
    <div className="footer">
      <footer className="py-5 bg-dark ">
        <div className="container">
          <Container>
            <Row>
              <Col><a href="https://twitter.com/pideck_player?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @pideck_player</a> </Col>
              <Col><p className="m-0 text-center text-white">Copyright &copy; PiDeck 2021</p></Col>
              <Col>
              <a href="https://discord.gg/yAdf7tj9FP" className="btn btn-sm btn-primary" target="_blank" >Discord</a> <a href="https://www.youtube.com/channel/UCQp78gi8cMz-F1tO7aPI0cw" target="_blank" className="btn btn-sm"><img src={youtubelogo} width="80"></img></a>
              </Col>
            </Row>
          </Container>          
          
        </div>
      </footer>
    </div>
  );
}

export default Footer;