// import React, { useEffect } from "react";
// import { useParams } from "react-router";
import { Container } from 'react-bootstrap';


function Post() {
//  let { postSlug } = useParams();

//  useEffect(() => {
    // Fetch post using the postSlug
//  }, [postSlug]);

  return (
    <div>
      <Container>
        <h1 className="mt-5">PiDeck Version 1.1.2 released</h1>
        <p>
          PiDeck is a low cost solution to adding videos to your live streams.
          </p>
          <h2>New Features</h2>
          <ul>
            <li>Set loop status for each clip</li>
            <li>Set start and Stop times for clip</li>
            <li>Show freespace for the clips folder</li>
            <li>CLIPS folder is now a partition on SDCard, insert SDcard in to Mac or Windows PC and drag and drop clips over.</li>
          </ul>

        <p>For more details on setting up and using PiDeck please review our <a href="https://pideck.m0guy.com/support" target="_blank" rel="noreferrer">support pages</a></p>
        <p>PiDeck boots in under a minute and is ready to playback your clips, can be controlled natively from Blackmagic ATEM devices.</p>
        <p>PiDeck is designed for the low cost <a href="https://www.raspberrypi.org" target="_blank" rel="noreferrer" >Raspberry Pi</a> single board computers. In fact it will run on any of them from the Pi Zero and up.</p>
        <p>Download a demo version now and test it out. </p>
      </Container>
    </div>
  );
}

export default Post;
