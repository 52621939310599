import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import splash02 from '../components/images/splash02.png';
import uploadplay from '../components/images/Upload-Play.gif';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

function Home() {
  const [products, setProducts] = useState();

  function getProducts() {
    axios(`${process.env.REACT_APP_API}/api/v1/products.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'auth_token ' + sessionStorage['token']
      }
    }).then((response) => { setProducts(response.data['products']) }, (error) => {
      console.log("ERROR", error);
      return Promise.reject(error);
    })
  }

  useEffect(() => {
    getProducts();
  }, [])

  return (
    <div className="home">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <Carousel pause='hover' interval={10000}>
              <Carousel.Item interval={10000}>
              <img className="img-fluid rounded mb-4 mb-lg-0" src={splash02} alt="" />
              </Carousel.Item>
              <Carousel.Item interval={20000}>
                <img className="img-fluid rounded mb-4 mb-lg-0" src={uploadplay} alt="" />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">PiDeck</h1>
            <p>PiDeck is an HDMI Video playback device. </p>
            <p>Turn any Raspberry Pi into a full functional HDMI video playback device.</p>
            <ul>
              <li>Play video clips using any Raspberry Pi from Pi-Zero and up.</li>
              <li>Liven up your streams using animated titles, bugs, etc without tying up a laptop or buying other expensive devices.</li>
              <li>Control from Blackmagic ATEM devices</li>
              <li>Simple Web GUI</li>
              <li>Software license £45.95 for each Pi</li>
            </ul>
            <span><Link to="/signup">Create</Link> an account to order, payments via PayPal.</span>
            <p></p>
            <p>Download a fully functional demo.</p>
            {products ? <a className="btn btn-primary" href={"https://pideck.m0guy.com/"+ products[0].filename}>Download</a> : "" }
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;