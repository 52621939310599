import axios from 'axios';
import React from 'react';
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { Form, Table, Col, Row, Container } from 'react-bootstrap';
import { Input } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import { BsFillTrashFill } from "react-icons/bs";
import moment from 'moment';



const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function Profile(props) {
  const [userProfile, setUserProfile] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginok, setLoginok] = useState(true);
  const [update, setUpdate] = useState(false);




  function LicenseForm(props) {
    const [deviceid, setDeviceid] = useState('')
    const [devicevalidation, setDevicevalidation] = useState('');
    const [paymentError, setPaymentError] = useState('');

    const [disableAdd, setDisableAdd] = useState(true);
    const { invoice } = props;

    function addLicense(e, invoice, deviceid) {
      // validate deviceid
      e.preventDefault();
      const body = {
        'license': {
          'deviceid': deviceid,
          'invoice_id': invoice
        }
      }
      if (sessionStorage['token']) {
        axios.post(`${process.env.REACT_APP_API}/api/v1/licenses.json`, body, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'auth_token ' + sessionStorage['token']
          }
        }).then((response) => {
          if (response.data.error || response.data.deviceid) {
            if (response.data.error) {
              setPaymentError(response.data.error);
            }
            if (response.data.deviceid) {
              if (response.data.deviceid === deviceid) {
                setUpdate(true);
              } else {
                setDevicevalidation(response.data.deviceid);
              }
            }
            setUpdate(false);
          } else {
            setPaymentError('');
            setUpdate(true);
          }
        }, (error) => {
          console.log("ERROR", error);
          return Promise.reject(error);
        })
      }
    }

    function checkDeviceID(e) {
      setDeviceid(e.target.value.toUpperCase());
      if (e.target.value.length === 17) {
        if (e.target.value.startsWith("B8:27:EB:") || e.target.value.startsWith("DC:A6:32:") || e.target.value.startsWith("E4:5F:01:") || e.target.value.startsWith("D8:3A:")) {
          setDevicevalidation("DeviceID OK");
          setDisableAdd(false);
        }
      } else {
        setDevicevalidation("DeviceID is not valid");
        setDisableAdd(true);
      }
    }
    return [
      <tr key="licenseForm">
        <td><Form><Row><Col><Input type="text" name={"deviceID-" + invoice} onChange={(e) => checkDeviceID(e)} value={deviceid} placeholder="AA:BB:CC:DD:EE" pattern="/^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/" size="20" />
        </Col><Col>
            <button className="btn btn-primary" disabled={disableAdd} onClick={(e) => (addLicense(e, invoice, deviceid))}>Add</button>
          </Col></Row>
        </Form>
          {devicevalidation ? <label htmlFor="DeviceNotValid">{devicevalidation}</label> : ""}
          {paymentError ? <label htmlFor="paymentNotCompleted" className="label error">{paymentError}</label> : ""}
        </td>
        <td align="left"></td>
        <td></td>
      </tr>
    ];
  }

  function LicenseRow(props) {
    function deleteLicense(e, deviceid) {
      e.preventDefault();
      const body = {
        'license': {
          'deviceid': deviceid
        }
      }
      if (sessionStorage['token']) {
        axios.post(`${process.env.REACT_APP_API}/api/v1/license/delete.json`, body, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'auth_token ' + sessionStorage['token']
          }
        }).then((response) => {
          setUpdate(true);
        }, (error) => {
          console.log("ERROR", error);
          return Promise.reject(error);
        })
      }
    }
    const { index, license } = props;
    return [
      <tr key={"LicRow-" + index}>
        <td>{license.deviceid}</td>
        <td>{license.active ? "Active" : "Revoked"}</td>
        <td>{license.lastcheckin ? moment(license.lastcheckin).format('YYYY-MM-DD HH:MM') : "Never "}
          {!license.lastcheckin ? <button className="btn btn-danger btn-sm" onClick={(e) => deleteLicense(e, license.deviceid)}><BsFillTrashFill /></button> : ""}</td>
      </tr>
    ];
  }

  function InvoiceTableRow(props) {
    const { index, invoice } = props;
    return [
      <>
        <tr key={"line-" + index}>
          <td key={"id-" + index}>{index + 1}</td>
          <td key={"sku-" + index}>{invoice.product.sku}</td>
          <td key={"pname-" + index}>{invoice.product.name}</td>
          <td key={"qty-" + index}>{invoice.qty}</td>
          <td key={"unitprice-" + index}>£{invoice.price}</td>
          <td key={"total-" + index}>£{Number(invoice.qty * invoice.price).toFixed(2)}</td>
          <td key={"orderid-" + index}>{invoice.orderID}</td>
          <td key={"lice-" + index}>{invoice.licenses.length}/{invoice.qty}</td>
        </tr>
        <tr>
          <td className="uk-background-muted" colSpan={8}>
            <div className="uk-width-3-4">
              <Container>
                <h3>Licensed Devices</h3>
                <Table size="sm" striped bordered hover>
                  <thead>
                    <tr><th>Device ID</th><th>Key</th><th>Checkin</th></tr>
                  </thead>
                  <tbody>
                    {invoice.licenses
                      ? invoice.licenses.map((license, index) =>
                        <LicenseRow key={"LicRow-" + index} index={index} license={license} />
                      ) : ""}
                    {invoice.totalActive < invoice.qty ? <LicenseForm invoice={invoice.id} /> : ""}
                  </tbody>
                </Table>
              </Container>
            </div>
          </td>
        </tr>
      </>
    ];
  }


  function getProfile() {

    if (sessionStorage['token']) {
      axios.get(`${process.env.REACT_APP_API}/api/v1/profile.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'auth_token ' + sessionStorage['token']
        }
      }).then((response) => { setUserProfile(response.data); props.firstnameUpdate(); }, (error) => {
        console.log("ERROR", error);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('firstname');
        setLoginok(false);
        return Promise.reject(error);
      })
    } else {
      setLoginok(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    setUserProfile('');
    getProfile();
    setLoading(false);
    setUpdate(false);
  }, [loading, update]);

  function OrderLine() {
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [products, setProducts] = useState();
    const [qty, setqty] = useState(1);
    const [lineTotal, setLineTotal] = useState(0);

    const createOrder = (data, actions) => {
      return actions.order.create({
        purchase_units: [{
          description: "PiDeck HDMI Playback",
          amount: {
            value: lineTotal,
            currency_code: "GBP",
            breakdown: {
              item_total: {
                currency_code: "GBP",
                value: lineTotal
              }
            }
          },
          items: [
            {
              name: products[selectedProduct - 1].sku,
              quantity: qty,
              unit_amount: {
                currency_code: "GBP",
                value: products[selectedProduct - 1].price
              }
            }
          ]
        },
        ],
      });
    };

    const onApprove = (data, actions) => {
      const body = {
        'invoice': {
          'qty': qty,
          'price': products[selectedProduct - 1].price,
          'product_id': products[selectedProduct - 1].id,
          'orderID': data.orderID,
          'payerID': data.payerID,
          'billingToken': data.billingToken,
          'paymentID': data.paymentID
        }
      }
      axios.post(`${process.env.REACT_APP_API}/api/v1/invoices.json`, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'auth_token ' + sessionStorage['token']
        }
      }).then((response) => {
        setUpdate(true);
      }, (error) => {
        console.log("ERROR", error);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('firstname');
        return Promise.reject(error);
      })

      return actions.order.capture();
    };

    useEffect(() => {
      getProducts();
    }, []);

    useEffect(() => {
      if (selectedProduct > 0) {
        setLineTotal(Number(products[selectedProduct - 1].price * qty).toFixed(2));
      }
    }, [selectedProduct, qty, products]
    );


    async function getProducts() {
      await axios(`${process.env.REACT_APP_API}/api/v1/products.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'auth_token ' + sessionStorage['token']
        }
      }).then((response) => { setProducts(response.data['products']) }, (error) => {
        console.log("ERROR", error);
        return Promise.reject(error);
      })
    }

    return (
      <>{products ? <>
        <Container>
          <Table >
            <thead><tr><th>Product</th><th>Description</th><th>Unit price</th><th>Quantity</th><th>Total</th></tr></thead>
            <tbody>
              <tr>
                <td>
                  <Form.Select aria-label="Select a Product" onChange={(a) => { setSelectedProduct(a.target.value) }}>
                    <option key="0" value="0" >Select a product</option>
                    {products.map((prod, index) =>
                      <option key={"Prods-" + index + 1} value={index + 1}>{prod.name}</option>
                    )}
                  </Form.Select>
                </td>
                <td>{selectedProduct > 0 ? products[selectedProduct - 1].description : ""}</td>
                <td>£{selectedProduct > 0 ? products[selectedProduct - 1].price : ""}</td>
                <td width="100"><Input type="number" onChange={(a) => { setqty(a.target.value) }} value={qty} maxLength="3" size="10" min="1" max="200" /></td>
                <td>£{lineTotal > 0 ? lineTotal : ""}</td>
              </tr>
              <tr><td colSpan="3"></td><td colSpan="2" align="right">
                <PayPalButton
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                />
              </td></tr>
            </tbody>
          </Table>
        </Container>
      </>
        : ""}

      </>

    )
  }

  if (!loginok) {
    return (<Navigate redirect to="/login" />);
  }

  return (
    <div>
      {loading ?
        <>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </> : ""
      }

      {!loading && userProfile ?
        <>

          <Container>
            <h1> User Profile</h1>
            <table><tbody>
              <tr key="firstname"><td align="right"><strong>Firstname</strong></td><td align="left">{userProfile.user.firstname}</td></tr>
              <tr key="surname"><td align="right"><strong>Surname</strong></td><td align="left">{userProfile.user.surname}</td></tr>
              <tr key="email"><td align="right"><strong>E-Mail</strong></td><td align="left">{userProfile.user.email}</td></tr>

            </tbody></table><br />


          </Container>

          {userProfile.invoices.length > 0 ?
            <>
              <Container>
                <h2>Orders</h2>
                <Table striped bordered hover>
                  <thead>
                    <tr key="order header">
                      <th />
                      <th>SKU</th>
                      <th>Product</th>
                      <th>Qty</th>
                      <th>Unit Price</th>
                      <th>Total</th>
                      <th>Payment Reference</th>
                      <th>Active Licenses</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userProfile.invoices
                      ? userProfile.invoices.map((invoice, index) =>
                        <InvoiceTableRow key={"Inv-" + index} index={index} invoice={invoice} />
                      )
                      : ""
                    }
                  </tbody>
                </Table>
              </Container>
            </>
            : ""}
          <OrderLine />
        </> : ""}
    </div>
  )
}

export default Profile;

