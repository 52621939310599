import './App.css';
// import React, { useEffect } from "react";
import { useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  Navigation,
  Footer,
  Home,
  Blog,
  Posts,
  Post,
  Login,
  PasswordReset,
  NewPassword,
  Logout,
  Newuser,
  Register,
  Profile
} from "./pages";

function App() {
  const [firstname, setFirstname] = useState('');

  const firstnameUpdate = () => {
    setFirstname(sessionStorage['firstname'])
  }

  return (
    <div className="App">
      <Router>
        <Navigation firstname={firstname} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<Blog />}>
            <Route path="" element={<Posts />} />
            <Route path=":postSlug" element={<Post />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/reset_password" element={<PasswordReset />} />
          <Route path="/new_pass" element={<Home />} />
          <Route path="/new_pass/:token" element={<NewPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Home />} />
          <Route path="/register/:token" element={<Register />} />
          <Route path="/signup" element={<Newuser />} />
          <Route path="/profile" element={<Profile firstnameUpdate={firstnameUpdate}/>} />
        </Routes>
        <br />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
