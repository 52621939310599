import React, { SyntheticEvent } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import EntryCard from '../components/EntryCard/EntryCard';
import InputGroup from '../components/InputGroup/InputGroup';
import Input from '../components/Input';
import Button from '../components/Button';
import validator from 'validator';


function Newuser() {
  const [email, setEmail] = useState('');
  const [tokCreated, setTokCreated] = useState(false);
  const [emailError, SetEmailError] = useState('');
  const [notvalid, setNotvalid] = useState(true);


  const validateEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email) ) {
      SetEmailError('');
      setNotvalid(false);
      setEmail(email);
    } else {
      SetEmailError('Email not valid');
      setNotvalid(true);
    }
  }

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API}/api/v1/user/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: {
          email
        }
      })
    })

    const content = await response.json();
    if (content.status === false) {
      SetEmailError('E-Mail already Registered');
    }
    // check the user was created
    if (content.status === "ok") {
      setTokCreated(true);
    }
  }

  function WaitForEmail() {
    return (
      <EntryCard>
        <h2>E-Mail Sent</h2>
        <p>Thank you for requesting an account. An E-Mail has been sent with a link to create your account. Please check your e-mail and follow the instructions to complete your registration.</p>
      </EntryCard>
    )
  }

  return (
    <>
    { tokCreated ? <WaitForEmail /> :
      <EntryCard>
        <h2>Signup</h2>
        <form onSubmit={submit}>
          <InputGroup>
            <label htmlFor="login-email">E-Mail Address</label>
            <Input type="text" onChange={(e) => validateEmail(e)} placeholder="E-mail address" id="email"/>
            <span style={{ fontWeight: 'bold', color: 'red', marginTop: 0}}>{emailError}</span>
          </InputGroup>

          <Button type="submit" full disabled={notvalid}>Signup</Button>
        </form>
        <span>
          Already have an account?
          <Link to="/Login">Login</Link>
        </span>
      </EntryCard>
    }
    </>
  )
}

export default Newuser;
