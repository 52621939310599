import React from 'react'
import { useState, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import EntryCard from '../components/EntryCard/EntryCard';
import InputGroup from '../components/InputGroup/InputGroup';
import Input from '../components/Input';
import Button from '../components/Button';

function Login(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginerror, setLoginerror] = useState('');
  const [loginok, setLoginok] = useState(false);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API}/authenticate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password
      })
    });

    const content = await response.json();
    if (content.error) {
      setLoginerror(content.error.user_authentication)
      setLoginok(false);
    } else {
      setLoginerror('');
      window.sessionStorage.setItem('token', content.auth_token);
      window.sessionStorage.setItem('firstname', content.firstname);
      setLoginok(true);

    }
  }

  if (loginok) {
    return (<Navigate replace to="/profile" />);
  }

  return (
      <EntryCard>
        <h2>Log in</h2>
        <form onSubmit={submit}>
          <InputGroup>
            <label htmlFor="login-email">E-Mail Address</label>
            <Input type="text" onChange={e => setEmail(e.target.value)} placeholder="E-mail address" id="email" />
          </InputGroup>
          <InputGroup>
            <label htmlFor="login-password">Password</label>
            <Input type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" id="password" />
          </InputGroup>
          {loginerror ? <label className="label error"> {loginerror} </label> : ''}
          <Button type="submit" full>Login</Button>
        </form>
        <span>
          Don't yet have an account?
          <Link to="/signup">Sign up</Link>
        </span>
        <span>Forgotten your password? <Link to="/reset_password">Reset Password</Link></span>
      </EntryCard>
  )
}


export default Login;
