import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import { Nav, NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import { Link } from "react-router-dom";

function Navigation(props) {

  return (
    <div className="navigation sticky-top">
      <Navbar collapseOnSelect variant="dark" bg="dark" expand="lg" >
        <Container>
        <Navbar.Brand href="/">PiDeck</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto navbar">
          </Nav>
          <Nav className="ml-auto">
            <Link to="/news" className="nav-link">News</Link>
            <Nav.Link href="https://pideck.m0guy.com/support" target="_blank" rel="noreferrer" className="nav-link">Support</Nav.Link>
            {!props.firstname ?
              <>
                <Link to="/login" className="nav-link">Login</Link>
              </>
              :
              <NavDropdown title={props.firstname} id="collasible-nav-dropdown">
                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>

    </div >
  );
}

export default Navigation;