import React from "react";
import { Link } from "react-router-dom";
import Post01img from '../../components/images/webpage.png';

function Posts() {
  return (
    <div className="home">
      <div class="container">
        <Link to="/news/PiDeck-Version-1.0-release">
          <div class="row align-items-center my-5">
            <div class="col-lg-7">
              <img
                class="img-fluid rounded mb-4 mb-lg-0"
                src={Post01img}
                alt=""
              />
            </div>
            <div class="col-lg-5">
              <h1 class="font-weight-light">PiDeck Version 1.1.2 Released</h1>
              <p>
                We are pleased to announce the release of Version 1.1.2 of PiDeck, the low cost HDMI video playback device.

              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Posts;